import { createSlice } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
  activeIndex: 0,
};

export const setActiveIndex = (changeIndex) => {
  return {
    type: 'tabActiveIndexData/setActiveIndex',
    payload: changeIndex
  };
};


// Create a slice to manage the state
const activeIndexDataSlice = createSlice({
  name: 'tabActiveIndexData',
  initialState,
  reducers: {
    // Reducer to handle setting the room name
    setActiveIndex: (state, action) => {
      state.activeIndex = action.payload;
    },
  },
 

});

// Export the reducer
export default activeIndexDataSlice.reducer;